import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import LinkWrapper from '../common/LinkWrapper';
import ArrowMore from '../../assets/svg/arrow_more.svg';
import * as styles from './PartnersListItem.module.scss';

export type PartnersListItemProps = {
  title: string;
  text: string;
  logo: React.ReactNode;
  href: string;
}
const PartnersListItem = ({ title, text, logo, href }: PartnersListItemProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <LinkWrapper className={styles.wrapperInner} url={href}>
        <div>
          <div className={styles.logoBox}>{logo}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{<Trans>{text}</Trans>}</div>
        </div>
        <div className={styles.arrow}>
          <ArrowMore />
        </div>
      </LinkWrapper>
    </div>
  );
};

export default PartnersListItem;
