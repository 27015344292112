import React from 'react';
import { graphql } from 'gatsby';
import MainWrapper from '../../layouts';
import PartnersList from '../../components/Partners/PartnersList';
import DownBlock from '../../components/DownBlock/DownBlock';

const PartnersPage = (): JSX.Element => {
  return (
    <MainWrapper>
      <PartnersList />
      <DownBlock />
    </MainWrapper>
  );
};

export default PartnersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["partnersPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
