// extracted by mini-css-extract-plugin
export var antBtnLg = "PartnersListItem-module--ant-btn-lg--DXhP8";
export var antBtnPrimary = "PartnersListItem-module--ant-btn-primary--o3vgj";
export var antFormItem = "PartnersListItem-module--ant-form-item--1tLdO";
export var antFormItemLabel = "PartnersListItem-module--ant-form-item-label--2NISf";
export var antInput = "PartnersListItem-module--ant-input--TYu4B";
export var antMessageNotice = "PartnersListItem-module--ant-message-notice--TfVna";
export var antMessageNoticeContent = "PartnersListItem-module--ant-message-notice-content--9wqJc";
export var arrow = "PartnersListItem-module--arrow--ZWUv9";
export var errorMessage = "PartnersListItem-module--errorMessage--71m8Z";
export var logoBox = "PartnersListItem-module--logoBox--XTOGe";
export var text = "PartnersListItem-module--text--IWNZr";
export var title = "PartnersListItem-module--title--2DLKq";
export var wrapper = "PartnersListItem-module--wrapper--O6W7F";
export var wrapperInner = "PartnersListItem-module--wrapperInner--gTH4d";