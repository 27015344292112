// extracted by mini-css-extract-plugin
export var antBtnLg = "PartnersList-module--ant-btn-lg--RxZDp";
export var antBtnPrimary = "PartnersList-module--ant-btn-primary--ukdRg";
export var antFormItem = "PartnersList-module--ant-form-item--DsqZa";
export var antFormItemLabel = "PartnersList-module--ant-form-item-label--PODaE";
export var antInput = "PartnersList-module--ant-input--UCLwQ";
export var antMessageNotice = "PartnersList-module--ant-message-notice--iv8Jd";
export var antMessageNoticeContent = "PartnersList-module--ant-message-notice-content--0rg+f";
export var description = "PartnersList-module--description--e+8eY";
export var errorMessage = "PartnersList-module--errorMessage--Vl-ZG";
export var partners = "PartnersList-module--partners--N8Hrf";
export var title = "PartnersList-module--title--oRksb";
export var wrapper = "PartnersList-module--wrapper--ZSsot";