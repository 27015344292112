import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ImageContainer from '../ImageContainer/ImageContainer';
import keitaro from '../../assets/images/partners/keitaro_preview.png';
import keitaro2x from '../../assets/images/partners/keitaro_preview_2x.png';
import astroproxy from '../../assets/images/partners/astroproxy_preview.png';
import astroproxy2x from '../../assets/images/partners/astroproxy_preview_2x.png';
import netnut from '../../assets/images/partners/netnut_preview.png';
import netnut2x from '../../assets/images/partners/netnut_preview_2x.png';
import iproxy from '../../assets/images/partners/iproxy_preview.png';
import iproxy2x from '../../assets/images/partners/iproxy_preview_2x.png';
import scenum from '../../assets/images/partners/scenum_preview.png';
import scenum2x from '../../assets/images/partners/scenum_preview_2x.png';
import ContentWrap from '../ContentWrap/ContentWrap';
import BlueSpan from '../common/typography/BlueSpan';
import {
  ASTRO_PROXY_NAME,
  I_PROXY_ONLINE_NAME,
  KEITARO_NAME,
  NET_NUT_PROXY_NETWORK_NAME, SCENUM_NAME,
} from '../../const/parnters.const';
import {
  PARTNERS_ASTRO_PROXY_ROUTE,
  PARTNERS_I_PROXY_ONLINE_ROUTE,
  PARTNERS_KEITARO_ROUTE,
  PARTNERS_NETNUT_PROXY_NETWORK_ROUTE, PARTNERS_SCENUM_ROUTE,
} from '../../const/routes.const';
import * as styles from './PartnersList.module.scss';
import PartnersListItem, { PartnersListItemProps } from './PartnersListItem';

const PARTNERS: PartnersListItemProps[] = [
  {
    title: SCENUM_NAME,
    text: 'Scenum.io is a unique service for automatic farming of Facebook, Google, and other accounts using common browsers.',
    logo: <ImageContainer src={scenum} srcSet={{ _2x: scenum2x }} />,
    href: PARTNERS_SCENUM_ROUTE,
  },
  {
    title: KEITARO_NAME,
    text: 'Keitaro - Ultimate advertising tracker designed for media buyers and publishers. Optimize, control, and protect your traffic. Focus on scaling up and generating profit.',
    logo: <ImageContainer src={keitaro} srcSet={{ _2x: keitaro2x }} />,
    href: PARTNERS_KEITARO_ROUTE,
  },
  {
    title: ASTRO_PROXY_NAME,
    text: 'Superior enterprise-grade infrastructure for gathering and processing web data. Highly compatible, easy to use, and fully reliable, AstroProxy is intended to perform web analytics of any scale.',
    logo: <ImageContainer src={astroproxy} srcSet={{ _2x: astroproxy2x }} />,
    href: PARTNERS_ASTRO_PROXY_ROUTE,
  },
  {
    title: NET_NUT_PROXY_NETWORK_NAME,
    text: 'Collect any web data with NetNut’s unique residential proxy network with maximum speed and anonymity. Get access to 20M+ residential and datacenter IPs worldwide and never get blocked again.',
    logo: <ImageContainer src={netnut} srcSet={{ _2x: netnut2x }} />,
    href: PARTNERS_NETNUT_PROXY_NETWORK_ROUTE,
  },
  {
    title: I_PROXY_ONLINE_NAME,
    text: 'Create and set up mobile proxies using your Android phone. IP address change, HTTP and SOCKS5 support, Telegram bot for notifications, and more.',
    logo: <ImageContainer src={iproxy} srcSet={{ _2x: iproxy2x }} />,
    href: PARTNERS_I_PROXY_ONLINE_ROUTE,
  },
];

const PartnersList = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <h1 className={styles.title}>
        <Trans i18nKey="Octo Browser Partners">
          <BlueSpan>Octo Browser</BlueSpan> Partners
        </Trans>
      </h1>
      <div className={styles.description}>
        <Trans>
          Here you will find discounts and promotions from popular services. Make sure to claim
          unique offers to save your resources and time.
        </Trans>
      </div>
      <div className={styles.partners}>
        {PARTNERS.map((partner) => (
          <PartnersListItem {...partner} key={partner.href} />
        ))}
      </div>
    </ContentWrap>
  </div>
);

export default PartnersList;
